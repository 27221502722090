import css from "styled-jsx/css";

export const styles = css`
  @media (max-width: 30em) {
    .hero__brand--priceblocs {
      height: 3rem;
    }
  }
  @media (min-width: 30em) and (max-width: 60em) {
    .hero__brand--priceblocs {
      height: 3rem;
    }
  }
  @media (min-width: 60em) {
    .hero__brand--priceblocs {
      height: 4rem;
    }
  }

  .hero__logo {
    background-image: url("/assets/hero__logo.png");
  }

  .bg__hero::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    pointer-events: none;
    height: 100%;
    background-color: transparent;
    /* transform: scale(1.3) rotate(33deg); */
    background-repeat: no-repeat;
    z-index: -1;
  }
  @media (max-width: 30em) {
    .bg__hero::before {
      background-position: top;
      background-size: contain;
    }
  }
  @media (min-width: 30em) and (max-width: 60em) {
    .bg__hero::before {
      background-position: right;
      background-size: contain;
      background-image: url("/assets/bg__hero--desktop.svg");
    }
  }
  @media (min-width: 60em) {
    .bg__hero::before {
      background-position: right;
      background-size: contain;
      background-image: url("/assets/bg__hero--desktop.svg");
    }
  }
`;
