import axios from "axios";
import { isServer } from "utils/view";
import { captureException } from "utils/error";
import { devLogger } from "utils/debug";
import { getConfig } from "./env";

const THRESHOLD = 0.5;

/**
 * Only allowed characters "A-Za-z/_"
 */
export const SECURE_ACTIONS = {
  SIGNUP: "signup",
  PAYMENT_LINK: "payment_link",
  PAYMENT_LINK_SETUP: "payment_link_setup",
  LOGIN: "login",
  RESET: "reset",
  RESET_CONFIRM: "reset_confirm",
  WAITLIST: "waitlist",
  EVALUATE: "evaluate"
};

export const DEFAULT_SECURITY_MESSAGE =
  "That request failed the reCAPTCHA security check.";

const verifyToken = ({ onSuccess, onError }) => {
  return (token) => {
    return axios({
      method: "post",
      url: `${getConfig("API_ROOT")}/v1/google/recaptcha/verify`,
      data: { token }
    })
      .then(
        /**
         * @param {Object} response`
         * @param {Object} response.data
         * @param {Boolean} response.data.success
         * @param {String} response.data.challenge_ts
         * @param {String} response.data.hostname
         * @param {Number} response.data.score
         * @param {String} response.data.action
         */
        ({ data }) => {
          devLogger("recaptchaVerify", data);
          data.score >= THRESHOLD
            ? onSuccess(data)
            : onError(
                new Error(`Failed verification with a score:${data.score}`)
              );
        }
      )
      .catch(onError);
  };
};

export const verifyRequest = ({ action, onSuccess, onError }) => {
  if (getConfig("GOOGLE_RECAPTCHA_KEY")) {
    if (!isServer()) {
      if (
        window.grecaptcha &&
        typeof window.grecaptcha.execute === "function"
      ) {
        window.grecaptcha
          .execute(getConfig("GOOGLE_RECAPTCHA_KEY"), { action })
          .then(verifyToken({ onSuccess, onError }));
      } else {
        const noTagError = new Error(
          "[Security] no google recaptcha tag included on page"
        );
        captureException(noTagError);
        if (onError) {
          onError(noTagError);
        }
      }
    }
  } else {
    const noKeyError = new Error("[Security] no recaptcha key set");
    captureException(noKeyError);
    if (onError) {
      onError(noKeyError);
    }
  }
};
