import React from "react";
import GoogleAuth from "components/GoogleAuth";
// import SlackAuth from "components/SlackAuth";

const OAuthButtons = () => (
  <div className="flex flex-column items-center">
    {/* <SlackAuth /> */}
    <div className="pt1">
      <GoogleAuth />
    </div>
  </div>
);

export default OAuthButtons;
