import React from "react";
import Image from "components/Image";
import { OAUTH_ROUTES } from "utils/paths";
import { cellGrow } from "constants/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { logout } from "actions/auth";

const GoogleAuth = ({ actions }) => {
  return (
    <a
      onClick={() => {
        actions.logout();

        window.location.href = OAUTH_ROUTES.GOOGLE;
      }}
      className="dib w-100 cell-grow pointer"
    >
      <Image
        src={`/assets/google_auth_button.png`}
        width={174}
        priority
        height={40}
        alt="Google auth"
      />
      <style jsx>{cellGrow}</style>
    </a>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      logout
    },
    dispatch
  )
});

export default connect(null, mapDispatchToProps)(GoogleAuth);
