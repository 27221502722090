import React from "react";
import PropTypes from "prop-types";
import { COLORS } from "utils/styles";

const DEFAULT_CLASSES = {
  container: "f7 w-100 tc lh-copy"
};

const RecaptchaNotice = ({ customClasses }) => {
  const classes = { ...DEFAULT_CLASSES, ...customClasses };
  return (
    <span className={classes.container} style={{ color: COLORS.disclaimer }}>
      This page is protected by reCAPTCHA and the Google{" "}
      <a
        href="https://www.google.com/policies/privacy/"
        target="_blank"
        className="color-primary fw5 w-100"
        rel="noreferrer noopener"
      >
        Privacy Policy
      </a>{" "}
      and{" "}
      <a
        href="https://www.google.com/policies/terms/"
        target="_blank"
        className="color-primary fw5 w-100"
        rel="noreferrer noopener"
      >
        Terms of Service
      </a>{" "}
      apply.
    </span>
  );
};

RecaptchaNotice.propTypes = {
  customClasses: PropTypes.shape({
    container: PropTypes.string
  })
};

export default RecaptchaNotice;
