import React from "react";
const DEFAULT_CLASSES = {
  container: "w-100 ph3 pv3 dib",
  body: "hairline-1 bb w-100"
};

const Separator = ({ customClasses }) => {
  const { container, body } = { ...DEFAULT_CLASSES, ...customClasses };
  return (
    <div className={container}>
      <div className={body} />
    </div>
  );
};

export default Separator;
